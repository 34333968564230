/*
Document   : thiauth/ThiauthRoutes
Created on : Mon 08 Apr 2024 17:28:55
Author     : Manasseh Achirka
 */

import { lazy } from 'react';
import Loadable from 'components/Loadable';

const IndexPage = Loadable(lazy(() => import('./IndexPage')));
const UserLogin = Loadable(lazy(() => import('./UserLoginII')));
const FirstTimeLogin = Loadable(lazy(() => import('./FirstTimeLogin')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const Logout = Loadable(lazy(() => import('./Logout')));
const NotFound = Loadable(lazy(() => import('./NotFound')));
const ResetUserLoginPassword = Loadable(lazy(() => import('./ResetUserLoginPassword')));
const CustomerAccount = Loadable(lazy(() => import('./CustomerAccount')));
const AboutUs = Loadable(lazy(() => import('./AboutUs')));
const ContactUs = Loadable(lazy(() => import('./ContactUs')));
const ProductList = Loadable(lazy(() => import('./ProductList')));
const ProductCategory = Loadable(lazy(() => import('./ProductCategory')));
const Gallery = Loadable(lazy(() => import('./Gallery')));

const ThiauthRoutes = [
  { path: '/', element: <IndexPage /> },
  { path: '/account/sign-in', element: <UserLogin /> },
  { path: '/account/sign-out', element: <Logout /> },
  { path: '/account/first-time-login', element: <FirstTimeLogin /> },
  { path: '/account/forgot-password', element: <ForgotPassword /> },
  { path: '/tims-control/404-not-found', element: <NotFound /> },
  { path: '/tims-control/recover-my-account', element: <ResetUserLoginPassword /> },
  { path: '/account/sign-up', element: <CustomerAccount /> },
  { path: '/about-us', element: <AboutUs /> },
  { path: '/contact-us', element: <ContactUs /> },
  { path: '/product-category', element: <ProductCategory /> },
  { path: '/product-list', element: <ProductList /> },
  { path: '/gallery', element: <Gallery /> }
];

export default ThiauthRoutes;
