/*
Document   : ecommerce/ecommerceRoutes
Created on : Sat 05 Aug 2023 17∶57∶39
Author     : Manasseh Achirka
*/

import { lazy } from 'react';
import Loadable from 'components/Loadable';
// import { authRoles } from '../../auth/authRoles';

const ProductCategory = Loadable(lazy(() => import('./productcategory')));
const ProductDetail = Loadable(lazy(() => import('./productdetail')));
const ProductBrand = Loadable(lazy(() => import('./productbrand')));
const ProductAttribute = Loadable(lazy(() => import('./productattribute')));
const ProductAttributeValue = Loadable(lazy(() => import('./productattributevalue')));
const ProductType = Loadable(lazy(() => import('./producttype')));
const ProductInventory = Loadable(lazy(() => import('./productinventory')));
const ProductStock = Loadable(lazy(() => import('./productstock')));
const MyRetailShop = Loadable(lazy(() => import('./myretailshop')));
const MyWholesaleShop = Loadable(lazy(() => import('./mywholesaleshop')));
const MyRetailShopAdmin = Loadable(lazy(() => import('./myretailshopadmin')));
const MyWholesaleShopAdmin = Loadable(lazy(() => import('./mywholesaleshopadmin')));
const Cart = Loadable(lazy(() => import('./myshoppingcart')));
const CheckOut = Loadable(lazy(() => import('./checkout')));
const ListOrders = Loadable(lazy(() => import('./list/orders-list')));
const ListOrdersAdmin = Loadable(lazy(() => import('./list/ordersadmin-list')));
const ListProductsOrderded = Loadable(lazy(() => import('./list/orders-by-products-list')));
const OrderInvoice = Loadable(lazy(() => import('./report/orderinvoice')));
const CustomerList = Loadable(lazy(() => import('./list/customer-list')));
const CustomerApproval = Loadable(lazy(() => import('./create/customer-approval')));
const ManageCustomerAccount = Loadable(lazy(() => import('./create/manage-customer-account')));
const ApproveOrders = Loadable(lazy(() => import('./create/approve-orders')));
const UpdateProductStockInfo = Loadable(lazy(() => import('./create/productstock-update')));
const ProductStockTransfer = Loadable(lazy(() => import('./create/productstock-transfer')));
const ProductStockCopyData = Loadable(lazy(() => import('./create/productstock-copydata')));
const StockBatch = Loadable(lazy(() => import('./stockbatch')));
const StockGroup = Loadable(lazy(() => import('./productstockgroup')));
const DiscountType = Loadable(lazy(() => import('./discounttype')));
const ApplyDiscount = Loadable(lazy(() => import('./applydiscount')));
const ProductDiscount = Loadable(lazy(() => import('./productdiscount')));
const ProductStockUpdateReport = Loadable(lazy(() => import('./report/productstock-updatereport')));
const ProductStockDownloadTemplate = Loadable(lazy(() => import('./create/productstock-downloadtemplate')));
const ProductStockUploadData = Loadable(lazy(() => import('./create/productstock-uploaddata')));
const ListOrdersIncompletePayments = Loadable(lazy(() => import('./list/orderincompletepayments-list')));
const OrderPaymentUpdate = Loadable(lazy(() => import('./create/order-payment-update')));
const SalesProfitAndLossReport = Loadable(lazy(() => import('./report/sales-profit-loss')));
const ProductSalesProfitAndLossReport = Loadable(lazy(() => import('./report/product-sales-profit-loss')));
const StoreValuationReport = Loadable(lazy(() => import('./report/store-valuation')));
const ProductPricesReport = Loadable(lazy(() => import('./report/product-prices-report')));

const EcommerceRoutes = [
  {
    path: '/ecommerce/product-category',
    element: <ProductCategory />
    // auth: authRoles.admin,
  },
  { path: '/account/view-customers', element: <CustomerList /> },
  { path: '/account/approve-customer', element: <CustomerApproval /> },
  { path: '/account/manage-customer', element: <ManageCustomerAccount /> },
  { path: '/ecommerce/product-detail', element: <ProductDetail /> },
  { path: '/ecommerce/product-brand', element: <ProductBrand /> },
  { path: '/ecommerce/product-attribute', element: <ProductAttribute /> },
  { path: '/ecommerce/product-attribute-value', element: <ProductAttributeValue /> },
  { path: '/ecommerce/product-type', element: <ProductType /> },
  { path: '/ecommerce/product-inventory', element: <ProductInventory /> },
  { path: '/ecommerce/inventory-stock', element: <ProductStock /> },
  { path: '/ecommerce/my-retail-shop', element: <MyRetailShop /> },
  { path: '/ecommerce/my-retail-shop-adu', element: <MyRetailShopAdmin /> },
  { path: '/ecommerce/my-wholesale-shop', element: <MyWholesaleShop /> },
  { path: '/ecommerce/my-wholesale-shop-adu', element: <MyWholesaleShopAdmin /> },
  { path: '/ecommerce/shopping-cart', element: <Cart /> },
  { path: '/ecommerce/shopping-checkout', element: <CheckOut /> },
  { path: '/ecommerce/orders', element: <ListOrders /> },
  { path: '/ecommerce/orders-adu', element: <ListOrdersAdmin /> },
  { path: '/ecommerce/orders-by-products', element: <ListProductsOrderded /> },
  { path: '/ecommerce/orders-incomplete-payments', element: <ListOrdersIncompletePayments /> },
  { path: '/ecommerce/order-payment-update', element: <OrderPaymentUpdate /> },
  { path: '/ecommerce/approve-orders', element: <ApproveOrders /> },
  { path: '/ecommerce/print-order-invoice', element: <OrderInvoice /> },
  { path: '/ecommerce/inventory-stock-update', element: <UpdateProductStockInfo /> },
  { path: '/ecommerce/inventory-stock-update-report', element: <ProductStockUpdateReport /> },
  { path: '/ecommerce/inventory-stock-transfer', element: <ProductStockTransfer /> },
  { path: '/ecommerce/inventory-stock-copy-data', element: <ProductStockCopyData /> },
  { path: '/ecommerce/inventory-stock-download-template', element: <ProductStockDownloadTemplate /> },
  { path: '/ecommerce/inventory-stock-upload', element: <ProductStockUploadData /> },
  { path: '/ecommerce/stock-batch', element: <StockBatch /> },
  { path: '/ecommerce/stock-group', element: <StockGroup /> },
  { path: '/ecommerce/discount-type', element: <DiscountType /> },
  { path: '/ecommerce/apply-discount', element: <ApplyDiscount /> },
  { path: '/ecommerce/product-discount', element: <ProductDiscount /> },
  { path: '/ecommerce/sales-profit-and-loss-report', element: <SalesProfitAndLossReport /> },
  { path: '/ecommerce/product-sales-profit-and-loss-report', element: <ProductSalesProfitAndLossReport /> },
  { path: '/ecommerce/store-valuation-report', element: <StoreValuationReport /> },
  { path: '/ecommerce/product-prices-report', element: <ProductPricesReport /> }
];

export default EcommerceRoutes;
