/*
Document   : appconfig/index
Created on : Sat 05 Aug 2023 18∶06∶16
Author     : Manasseh Achirka
*/

const appConfig = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead, e.g. like '/berry-material-react/react/default'
  basename: '',
  defaultPath: '/dashboard',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  modules: {
    tims: 'timscontrol',
    hr: 'hrm',
    ecomm: 'ecommerce',
    org: 'organization',
    uac: 'usersacc',
    fac: 'finacc',
    pay: 'payment'
  },
  EMAIL_REGEX: /^([a-zA-Z0-9._%+-])+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,4}$/,
  INTEGER_NUMBER_REGEX: /^[0-9]+$/,
  FLOAT_NUMBER_REGEX: /^[+-]?\d+(\.\d+)?$/,
  CURRENCY_SYMBOLS: ['₦', '$'],
  PERMITTED_ROUTES: [
    '/',
    '/dashboard',
    '/about-us',
    '/tims-control/404-not-found',
    '/account/sign-in',
    '/account/sign-out',
    '/account/first-time-login',
    '/account/forgot-password',
    '/tims-control/security-check',
    '/ecommerce/print-order-invoice',
    '/self-service/user-profile',
    '/self-service/change-password',
    '/human-resources/approve-staff/',
    '/human-resources/employee-activation/',
    '/tims-control/recover-my-account',
    '/account/manage-customer',
    '/human-resources/update-emp-info',
    '/ecommerce/order-payment-update'
  ],
  tableOptions: {
    search: true,
    download: false,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: 'checkbox',
    responsive: 'standard',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '100%',
    pagination: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 300, 500],
    selectableRows: 'none',
    overflowX: 'auto'
  },
  allowedImageTypes: 'image/gif,image/pjpeg,image/jpeg,image/png',
  stockUpdateChannel: {
    ADD_INTF: 'Add Interface',
    UPDATE_INTF: 'Update Interface',
    BULK_UPDATE_INTF: 'Stock Update Interface',
    COPY_STOCK_INTF: 'Copy Stock Interface',
    STOCK_UPLOAD_INTF: 'Stock Upload Interface',
    STOCK_TRANSFER_INTF: 'Stock Upload Interface'
  },
  // service provider settings
  productName: 'Decom',
  vendorName: 'Thimadoos IT Services',
  vendorWebsite: 'https://thimadoos.com.ng/',
  vendorPhoneno: '+23480-5229-0236',
  // Client info
  clientShortName: process.env.REACT_APP_CLIENT_SHORT_NAME,
  clientFullName: process.env.REACT_APP_CLIENT_FULL_NAME,
  clientPrivacyName: process.env.REACT_APP_CLIENT_PRIVACY_NAME,
  clientAddress: process.env.REACT_APP_CLIENT_ADDRESS,
  clientPhone: process.env.REACT_APP_CLIENT_PHONENO,
  clientPhone2: process.env.REACT_APP_CLIENT_PHONENOII,
  clientEmail: process.env.REACT_APP_CLIENT_EMAIL,
  clientEmail2: process.env.REACT_APP_CLIENT_EMAILII,
  clientSmallInvoiceHeadingName: process.env.REACT_APP_CLIENT_SMALL_INVOICE_HEADING_NAME,
  clientSmallInvoiceHeadingTag: process.env.REACT_APP_CLIENT_SMALL_INVOICE_HEADING_TAG,
  retailCustomer: process.env.REACT_APP_CLIENT_RETAIL_CUSTOMER,
  wholesaleCustomer: process.env.REACT_APP_CLIENT_WHOLESALE_CUSTOMER,
  thiAuthApiBaseUrl: process.env.REACT_APP_CLIENT_AUTH_API_BASE_URL,
  thiapiBaseUrl: process.env.REACT_APP_CLIENT_API_BASE_URL,
  mediaBaseUrl: process.env.REACT_APP_CLIENT_MEDIA_BASE_URL,
  mediaUrl: process.env.REACT_APP_CLIENT_MEDIA_URL,
  ENC_DEC_PHRASE: process.env.REACT_APP_CLIENT_ENC_DEC_PHRASE
};

export default appConfig;
