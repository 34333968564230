import { CircularProgress, LinearProgress } from '@mui/material';
import { Box, styled } from '@mui/system';
import { getMediaFullPath } from 'views/commons/commonhooks';

const StyledLoading = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    width: 'auto',
    height: '25px'
  },
  '& .circleProgress': {
    position: 'absolute',
    left: -7,
    right: 0,
    top: 'calc(50% - 25px)'
  }
}));

const Loading = () => {
  return (
    <StyledLoading>
      <Box position="relative">
        <img src={getMediaFullPath('app_imgs/servicemanager_logo.svg')} alt="" />
        <CircularProgress className="circleProgress" />
      </Box>
    </StyledLoading>
  );
};

const LoadingSpinnerWithMessage = ({ LoadingText }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
      {LoadingText && <div align="center">{LoadingText}</div>}
      <LinearProgress color="secondary" />
    </Box>
  );
};

export default Loading;
export { LoadingSpinnerWithMessage };
